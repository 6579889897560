






































































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Logo54',
})
export default class Logo54 extends Vue {}
